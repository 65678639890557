"use client";

import { KitchenCapacityChartMini } from "#/components/charts/kitchen-capacity-chart";
import { SnoozePopoverView } from "#/components/menu/menu-item-snooze-popover";
import { EnterpriseSection } from "#/components/subscription/enterprise-section";
import { SubscriptionCongrats } from "#/components/subscription/subscription-congrats";
import useSubscription from "#/hooks/data/subscription/use-subscription";
import { useTStream } from "#/hooks/use-t-stream";
import { analytics, events } from "#/lib/analytics";
import { cn } from "#/lib/utils";
import { DatadogActionNames } from "#/types.ts/other";
import { Badge } from "#/ui/badge";
import { Button } from "#/ui/button";
import { Card } from "#/ui/card";
import { DateTimePickerForm } from "#/ui/date-time-picker-form";
import { DrawerDialogBase } from "#/ui/drawer-dialog";
import { Separator } from "#/ui/separator";
import { FormatCents } from "#/utils/CurrencyUtils";
import { SUBSCRIPTION_TIERS } from "#/utils/SubscriptionUtils";
import { StarIcon } from "@heroicons/react/24/solid";
import { Check, Star, StoreIcon, XIcon } from "lucide-react";
import {
  cloneElement,
  ReactElement,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDebouncedCallback } from "use-debounce";

type SubscriptionModalProps = {
  tierPermissions?: SUBSCRIPTION_TIERS[];
  /** Feature entry point, if no component is provided we will override onClick */
  children?: ReactElement;
  /** Paywall specific component to show when not subscribed */
  component?: ReactElement;
  manage?: boolean;
  paywall?: "snooze" | "kitchen_capacity";
};

export const SubscriptionModal = (props: SubscriptionModalProps) => (
  <Suspense fallback={<></>}>
    <SubscriptionComponent {...props} />
  </Suspense>
);

const SubscriptionComponent = ({
  children,
  tierPermissions,
  component,
  paywall,
  manage = false,
}: SubscriptionModalProps) => {
  const [isOpen, setOpen] = useState(false);
  const { subscription, priceInfo, isPartnerByPassed } = useSubscription();
  const { t } = useTStream("common");
  const [showSuccess, setShowSuccess] = useState(false);

  const onView = useDebouncedCallback(() => {
    analytics.track(events.SUBSCRIPTION_STARTED, {
      unit_amount: priceInfo?.unit_amount,
      currency: priceInfo?.currency,
    });
  }, 1000);

  useEffect(() => {
    if (isOpen) {
      setShowSuccess(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && !manage) onView();
  }, [isOpen, manage]);

  const hasRequiredTier = useMemo(() => {
    if (manage) return true;
    if (isPartnerByPassed) return true;
    if (!subscription) return false;
    if (!tierPermissions?.length) return true;

    const currentTier = subscription.type_hr as SUBSCRIPTION_TIERS;
    return tierPermissions.includes(currentTier);
  }, [subscription, tierPermissions, manage]);

  const showUpgrade = useMemo(() => {
    if (manage) return false;
    if (!subscription?.type_hr || !tierPermissions?.length) return false;

    const currentTierIndex = Object.values(SUBSCRIPTION_TIERS).indexOf(
      subscription.type_hr as SUBSCRIPTION_TIERS,
    );
    const minRequiredTierIndex = Math.min(
      ...tierPermissions.map((tier) =>
        Object.values(SUBSCRIPTION_TIERS).indexOf(tier),
      ),
    );

    return currentTierIndex < minRequiredTierIndex;
  }, [subscription, tierPermissions, manage]);

  if (!hasRequiredTier || manage) {
    return (
      <>
        {component &&
          cloneElement(component, {
            ...component.props,
            onClick: (e: React.MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(true);
            },
            onPointerDown: (e: React.PointerEvent) => {
              e.preventDefault();
              e.stopPropagation();
            },
          })}

        {/* If you render children here, we will leak access to the feature */}
        {children &&
          !component &&
          cloneElement(children, {
            ...children?.props,
            onClick: (e: React.MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
              setOpen(true);
            },
            onPointerDown: (e: React.PointerEvent) => {
              e.preventDefault();
              e.stopPropagation();
            },
          })}
        <DrawerDialogBase
          open={isOpen}
          onOpenChange={setOpen}
          className={cn({
            "py-10": !paywall,
            "max-w-[700px]": !showUpgrade && !showSuccess,
          })}
        >
          <div data-testid="subscription-modal">
            {paywall === "snooze" && !showSuccess && (
              <SnoozeView
                setShowSuccess={setShowSuccess}
                onOpenChange={setOpen}
              />
            )}
            {paywall === "kitchen_capacity" && !showSuccess && (
              <PrepTimeKitchenView
                setShowSuccess={setShowSuccess}
                onOpenChange={setOpen}
              />
            )}
            {!paywall && showUpgrade && !showSuccess && (
              <UpgradeView
                setShowSuccess={setShowSuccess}
                onOpenChange={setOpen}
              />
            )}
            {!paywall && !manage && !showUpgrade && !showSuccess && (
              <SubscribeDialog
                open={isOpen}
                onOpenChange={setOpen}
                setShowSuccess={setShowSuccess}
              />
            )}
            {showSuccess && (
              <SubscriptionCongrats
                onContinue={() => {
                  setOpen(false);
                  setTimeout(() => {
                    setShowSuccess(false);
                  }, 1500);
                }}
              />
            )}
            {manage && !showSuccess && (
              <div className="space-y-4">
                <div className="px-16">
                  <p className="font-semibold leading-none tracking-tight text-xl">
                    {t("Manage Subscription")}
                  </p>
                  <p className="text-muted text-sm">
                    {t("Changes will take effect immediately")}
                  </p>
                </div>
                <div className="flex flex-col justify-evenly md:flex-row items-center">
                  <SubscriptionTier
                    setOpen={setOpen}
                    tier={SUBSCRIPTION_TIERS.per_month_location}
                    setShowSuccess={setShowSuccess}
                  />
                  <SubscriptionTier
                    setOpen={setOpen}
                    tier={SUBSCRIPTION_TIERS.per_month_location_premium}
                    setShowSuccess={setShowSuccess}
                  />
                </div>
              </div>
            )}
          </div>
        </DrawerDialogBase>
      </>
    );
  }

  return children;
};

function SubscribeDialog({ setShowSuccess, ...props }) {
  const { t } = useTStream("common");

  return (
    <div>
      <div className="my-2 px-10">
        <p className="font-semibold leading-none tracking-tight text-xl">
          {t("Start subscription")}
        </p>
        <p className="text-muted text-sm">
          {t("Start your subscription now to publish your menu")}
        </p>
      </div>
      <div className="px-10 space-y-4">
        <div className="flex flex-col md:flex-row items-center gap-4">
          <SubscriptionTier
            setOpen={props?.onOpenChange}
            tier={SUBSCRIPTION_TIERS.per_month_location}
            setShowSuccess={setShowSuccess}
          />
          <SubscriptionTier
            setOpen={props?.onOpenChange}
            tier={SUBSCRIPTION_TIERS.per_month_location_premium}
            setShowSuccess={setShowSuccess}
          />
        </div>
        <EnterpriseSection />
      </div>
    </div>
  );
}
export const SubscriptionTier = ({
  tier,
  setOpen,
  setShowSuccess,
  className,
}: {
  tier: SUBSCRIPTION_TIERS;
  setOpen?: (open: boolean) => void;
  setShowSuccess: (show: boolean) => void;
  className?: string;
}) => {
  const { subscription, allFeatures, isActive } = useSubscription();

  const isPremium = tier === SUBSCRIPTION_TIERS.per_month_location_premium;
  const { create, loading, priceInfo } = useSubscription();
  const { t } = useTStream("common");

  const onSelect = useCallback(async () => {
    await create(tier);
    setShowSuccess(true);
    if (setOpen) setOpen(false);
  }, [create, setOpen, tier, setShowSuccess]);

  return (
    <Card
      className={cn(
        {
          "p-5 rounded-xl": true,
          "bg-secondary text-secondary-foreground": isPremium,
          "bg-background text-secondary-foreground": !isPremium,
        },
        className,
      )}
    >
      <div className="space-y-6">
        {/* Header */}
        <div className="flex items-center gap-4">
          <div
            className={`p-3 rounded-full flex relative overflow-hidden items-center justify-center ${
              isPremium ? "bg-[#8257e6]" : ""
            }`}
          >
            {isPremium ? (
              <Star className="w-4 h-4 text-white" />
            ) : (
              <>
                <div className="absolute w-full h-full bg-primary opacity-[15%]" />
                <StoreIcon className="w-4 h-4 text-primary" />
              </>
            )}
          </div>
          <div>
            <div className="flex items-center space-x-3">
              <h2
                className={`text-lg font-500 mb-1 ${
                  isPremium ? "text-secondary-foreground" : "text-foreground"
                }`}
              >
                {isPremium ? t("Premium") : t("Standard")}
              </h2>
              {subscription?.type_hr === tier && (
                <Badge variant={isPremium ? "default" : "muted"}>
                  {t("Your plan")}
                </Badge>
              )}
            </div>
            <p
              className={`text-xs ${
                isPremium ? "text-secondary-muted" : "text-foreground"
              }`}
            >
              {isPremium
                ? t("Unlock full potential of Stream features")
                : t("Get full access to manage your orders")}
            </p>
          </div>
        </div>

        {/* Features Section */}
        <div className="space-y-6">
          <h3
            className={`text-base ${
              isPremium ? "text-secondary-foreground" : "text-foreground"
            }`}
          >
            {t("All items")}
          </h3>
          <ul className="space-y-4">
            {allFeatures.map((feature) => {
              const isIncluded = feature.includedIn.includes(
                isPremium ? "premium" : "standard",
              );
              return (
                <li
                  key={feature.name}
                  className={`flex items-center gap-3 ${
                    isIncluded ? "" : "opacity-50"
                  }`}
                >
                  {isIncluded ? (
                    <Check className="w-5 h-5 text-[#3dd598]" />
                  ) : (
                    <XIcon className="w-5 h-5 text-muted" />
                  )}
                  <span
                    className={`text-xs ${isPremium ? "" : "text-foreground"} ${
                      !isIncluded ? "line-through" : ""
                    }`}
                  >
                    {feature.name}
                  </span>
                </li>
              );
            })}
          </ul>
        </div>

        {/* Pricing and CTA */}
        <div
          className={`flex items-end border-t-[1px] ${
            isPremium ? "border-secondary-accent" : "border-border"
          } justify-between pt-4`}
        >
          <div>
            <span
              className={`text-xl font-600 ${
                isPremium ? "" : "text-foreground"
              }`}
            >
              {isPremium
                ? FormatCents(
                    priceInfo?.premium?.unit_amount,
                    priceInfo?.premium?.currency,
                  )
                : FormatCents(priceInfo?.unit_amount, priceInfo?.currency)}
            </span>
            <p
              className={`text-sm ${
                isPremium ? "text-secondary-muted" : "text-muted"
              }`}
            >
              {t("Per location / month")}
            </p>
          </div>
          {(!subscription?.type_hr || subscription?.type_hr !== tier) && (
            <Button
              variant={isPremium ? "default" : "outline"}
              onClick={onSelect}
              loading={loading}
              size="md"
              data-dd-action-name={
                isPremium
                  ? DatadogActionNames.subscribe_premium
                  : isActive
                  ? DatadogActionNames.downgrade
                  : DatadogActionNames.subscribe_standard
              }
            >
              {isPremium ? t("Upgrade") : isActive ? t("Downgrade") : "Upgrade"}
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
};

export const UpgradeView = ({
  setShowSuccess,
  onOpenChange,
}: {
  setShowSuccess: (show: boolean) => void;
  onOpenChange: (open: boolean) => void;
}) => {
  const { t } = useTStream("common");
  const { create, isUpdating, priceInfo, allFeatures } = useSubscription();

  const features = allFeatures.filter((feature) =>
    feature.includedIn.includes("premium"),
  );

  const onUpgrade = useCallback(async () => {
    await create(SUBSCRIPTION_TIERS.per_month_location_premium);
    setShowSuccess(true);
  }, [create, setShowSuccess]);

  return (
    <div className="px-10 space-y-4 mt-3">
      <div className="flex flex-col items-center space-y-4 pb-4">
        <div className="rounded-full bg-purple-500 p-4 relative">
          <div className="absolute inset-0 rounded-full bg-purple-500 animate-ping opacity-30"></div>
          <Star className="h-5 w-5 text-white relative z-10" />
        </div>
        <div className="space-y-1 text-center">
          <h2 className="text-xl font-500 tracking-tight">
            {t("Upgrade to Premium plan")}
          </h2>
          <p className="text-muted font-400 text-sm">
            {t("Unlock full potential of Stream features")}
          </p>
        </div>
      </div>

      <Separator />

      <div>
        <div className="space-y-4">
          <h3 className="text-sm font-500">
            {t("Everything in Standard, plus:")}
          </h3>
          <ul className="grid grid-cols-2 gap-3">
            {features
              .filter((feature) => !feature.includedIn.includes("standard"))
              .map((feature) => (
                <li key={feature.name} className="flex items-center">
                  <Check className="mr-2 h-4 w-4 text-emerald-500" />
                  <span className="text-muted text-xs">{feature.name}</span>
                </li>
              ))}
          </ul>
        </div>
      </div>

      <Separator />

      <div className="space-y-1">
        <div className="text-2xl font-500">
          {FormatCents(
            priceInfo?.premium?.unit_amount,
            priceInfo?.premium?.currency,
          )}
        </div>
        <p className="text-sm text-muted">{t("Per location / month")}</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        <Button
          onClick={() => onOpenChange(false)}
          variant="outline"
          className="w-full"
          data-dd-action-name={DatadogActionNames.dismiss_upgrade}
        >
          {t("Maybe later")}
        </Button>
        <Button
          className="w-full"
          onClick={onUpgrade}
          loading={isUpdating}
          data-dd-action-name={DatadogActionNames.upgrade_premium}
        >
          {t("Upgrade now")}
        </Button>
      </div>

      <Separator />
      <EnterpriseSection />
    </div>
  );
};

const SnoozeView = ({
  setShowSuccess,
  onOpenChange,
}: {
  setShowSuccess: (show: boolean) => void;
  onOpenChange: (open: boolean) => void;
}) => {
  const { t } = useTStream("common");
  const { create, isUpdating, priceInfo, allFeatures } = useSubscription();

  const features = allFeatures.filter((feature) =>
    feature.includedIn.includes("premium"),
  );

  const onUpgrade = useCallback(async () => {
    await create(SUBSCRIPTION_TIERS.per_month_location_premium);
    setShowSuccess(true);
  }, [create, setShowSuccess]);

  return (
    <>
      <div className="w-full flex items-center justify-center h-[310px] bg-background">
        <div className="relative">
          <div className="w-[330px] px-3 pt-1 overflow-hidden shadow-xl h-[230px] bg-card rounded-xl">
            <div className="scale-[0.93] translate-y-[-10px]">
              <SnoozePopoverView
                snoozeType="item_family"
                onChangeType={() => {}}
                onUpdateDate={() => {}}
                onRevert={() => {}}
                onSave={() => {}}
                snoozeDate={new Date()}
              />
            </div>
          </div>
          <div className="w-[180px] p-4 items-center justify-center flex flex-col absolute right-[-50px] bottom-[-25px] z-10 h-[100px] bg-card shadow-xl rounded-xl">
            {/* STAR */}
            <div className="relative w-full">
              <div className="rounded-full bg-purple-500 p-2 absolute right-[-30px] top-[-18px]">
                <div className="absolute inset-0 rounded-full bg-purple-500 animate-ping opacity-30"></div>
                <Star className="h-5 w-5 text-white relative z-10" />
              </div>
            </div>

            <div className="gap-2 translate-y-[5px]">
              <p className="text-[12px]">{t("Custom Snooze")}</p>
              <p className="text-[10px] text-muted">
                {t("Choose a custom date and time to snooze your items")}
              </p>
            </div>
            <div className="scale-[0.62]">
              <DateTimePickerForm date={new Date()} onUpdate={() => {}} />
            </div>
          </div>
        </div>
      </div>
      <div className="px-10 space-y-4 pb-4">
        <div className="flex flex-col items-center">
          <div className="space-y-1 text-start">
            <h2 className="text-xl font-500 tracking-tight">
              {t("Smart Menu Management with Advanced Item Snoozing")}
            </h2>
            <p className="text-muted font-400 text-sm">
              {t(
                "Take control of your menu availability with precision timing. Set custom snooze periods for menu items or entire categories, automatically making them available again at your specified date and time. Perfect for seasonal items, special promotions, or managing inventory gaps.",
              )}
            </p>
          </div>
        </div>

        <div>
          <div className="space-y-4">
            <h3 className="text-sm font-500">
              {t("Everything in Standard, plus:")}
            </h3>
            <ul className="grid grid-cols-2 gap-3">
              {features
                .filter((feature) => !feature.includedIn.includes("standard"))
                .map((feature) => (
                  <li key={feature.name} className="flex items-center">
                    <Check className="mr-2 h-4 w-4 text-emerald-500" />
                    <span className="text-muted text-xs">{feature.name}</span>
                  </li>
                ))}
            </ul>
          </div>
        </div>

        <Separator />

        <div className="space-y-1">
          <div className="text-2xl font-500">
            {FormatCents(
              priceInfo?.premium?.unit_amount,
              priceInfo?.premium?.currency,
            )}
          </div>
          <p className="text-sm text-muted">{t("Per location / month")}</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <Button
            onClick={() => onOpenChange(false)}
            variant="outline"
            className="w-full"
          >
            {t("Maybe later")}
          </Button>
          <Button className="w-full" onClick={onUpgrade} loading={isUpdating}>
            {t("Upgrade now")}
          </Button>
        </div>
      </div>
    </>
  );
};

const PrepTimeKitchenView = ({
  setShowSuccess,
  onOpenChange,
}: {
  setShowSuccess: (show: boolean) => void;
  onOpenChange: (open: boolean) => void;
}) => {
  const { t } = useTStream("common");
  const { create, isUpdating, priceInfo, allFeatures } = useSubscription();

  const features = allFeatures.filter((feature) =>
    feature.includedIn.includes("premium"),
  );

  const onUpgrade = useCallback(async () => {
    await create(SUBSCRIPTION_TIERS.per_month_location_premium);
    setShowSuccess(true);
  }, [create, setShowSuccess]);

  return (
    <>
      <div className="w-full flex items-center justify-center h-[310px] bg-background">
        <div className="relative">
          <div className="w-[330px] relative overflow-hidden p-4 space-y-2 shadow-xl h-[230px] bg-card rounded-xl">
            <div className="gap-2">
              <p className="text-[12px]">Kitchen Capacity (orders)</p>
              <p className="text-[10px] text-muted">
                How many items can your kitchen handle at once?
              </p>
            </div>

            <div className="left-[-10px] w-[300px] absolute bottom-[-20px]">
              <KitchenCapacityChartMini />
            </div>
          </div>
          <div className="w-[180px] p-4 space-y-2 items-center justify-center flex flex-col absolute right-[-50px] bottom-[-25px] z-10 h-[120px] bg-card shadow-xl rounded-xl">
            {/* STAR */}
            <div className="relative w-full">
              <div className="rounded-full bg-purple-500 p-2 absolute right-[-30px] top-[-30px]">
                <div className="absolute inset-0 rounded-full bg-purple-500 animate-ping opacity-30"></div>
                <StarIcon className="h-5 w-5 text-white relative z-10" />
              </div>
            </div>

            <div className="gap-2">
              <p className="text-[12px]">Kitchen Capacity (orders)</p>
              <p className="text-[10px] text-muted">
                How many items can your kitchen handle at once?
              </p>
            </div>
            <input
              type="number"
              min="1"
              disabled
              placeholder="Enter capacity..."
              className="w-full border rounded-xl text-xs bg-background px-3 py-1 border-border text-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2"
            />
          </div>
        </div>
      </div>
      <div className="px-10 space-y-4 pb-4">
        <div className="flex flex-col items-center">
          <div className="space-y-1 text-start">
            <h2 className="text-xl mt-3 font-500 tracking-tight">
              Maximize Efficiency with Kitchen Capacity Management
            </h2>
            <p className="text-muted mt-4 font-400 text-sm">
              Set a capacity limit to automatically manage the number of active
              orders your kitchen can handle at once. When the limit is reached,
              new orders are queued until space frees up, ensuring a consistent
              pace without overwhelming your team.
            </p>
          </div>
        </div>

        <div>
          <div className="space-y-4">
            <h3 className="text-sm font-500">
              {t("Everything in Standard, plus:")}
            </h3>
            <ul className="grid grid-cols-2 gap-3">
              {features
                .filter((feature) => !feature.includedIn.includes("standard"))
                .map((feature) => (
                  <li key={feature.name} className="flex items-center">
                    <Check className="mr-2 h-4 w-4 text-emerald-500" />
                    <span className="text-muted text-xs">{feature.name}</span>
                  </li>
                ))}
            </ul>
          </div>
        </div>

        <Separator />

        <div className="space-y-1">
          <div className="text-2xl font-500">
            {FormatCents(
              priceInfo?.premium?.unit_amount,
              priceInfo?.premium?.currency,
            )}
          </div>
          <p className="text-sm text-muted">{t("Per location / month")}</p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
          <Button
            onClick={() => onOpenChange(false)}
            variant="outline"
            className="w-full"
          >
            {t("Maybe later")}
          </Button>
          <Button className="w-full" onClick={onUpgrade} loading={isUpdating}>
            {t("Upgrade now")}
          </Button>
        </div>
      </div>
    </>
  );
};
